// Note: Requires flexslider 2, buddy.
jQuery(document).ready(function($) {
	
	$('.big-center-slider-wrapper .flexslider .slides').slick({
        centerMode: true,
        centerPadding: '13vw',
        dots: true,
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><span class="screen-reader-text">Next</span></button>',
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><span class="screen-reader-text">Previous</span></button>',
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    centerPadding: '24px'
                }
            }]
    });

    //get screen width
    var screenWidth = $(window).width();

    // get count of li items in the slider
    var slideCount = $('.big-center-slider-wrapper .slick-dots li').length;

    // set width of the slideCount elements to the width of the slider
    $('.big-center-slider-wrapper .slick-dots button').css('width', (screenWidth/slideCount) - 8 + 'px');

}); /* end of as page load scripts */